import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Select,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Box,
    HStack,
    useToast,
    Text,
    VStack,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from 'react-redux';
import { fetchKnowledgeBank, createKnowledgeBank, checkDocumentStatus, connectKnowledgeBank } from 'actions/knowledgeBankActions';
import { LanguageContext } from 'context/LanguageContext';
import Card from "components/card/Card.js";
import { uploadKnowledgeBankFile } from 'actions/tutorialAction';

const translations = {
    en: {
        question: "Question",
        answer: "Answer",
        addRow: "Add Question!",
        save: "Save",
        addNewRow: "Add New Question",
        selectQuestionType: "Select Question Type",
        single: "Single",
        tree: "Tree",
        cancel: "Cancel",
        connect: "Connect",
        txtToggleButton: "English Language",
        uploadDocument: 'Upload Document',
        chooseFile: 'Choose File',
        uploadFile: 'Upload File',
    },
    ar: {
        question: "سؤال",
        answer: "إجابة",
        addRow: "أضف صفًا!",
        save: "حفظ",
        addNewRow: "إضافة صف جديد",
        selectQuestionType: "اختر نوع السؤال",
        single: "فردي",
        tree: "شجرة",
        cancel: "إلغاء",
        connect: "يتصل",
        txtToggleButton: "اللغة العربية",
        uploadDocument: 'تحميل الوثيقة',
        chooseFile: 'اختيار الملف',
        uploadFile: 'تحميل الملف',
    }
};

const KnowledgeBank = () => {
    // const [language, setLanguage] = useState('ar');
    const { translate, language } = useContext(LanguageContext);
    const [rows, setRows] = useState([]);
    const [newRow, setNewRow] = useState({ id: '', question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();
    const toast = useToast();
    const { knowledgeBank, loading, isAvailable, checkDocumentStatusLoading } = useSelector((state) => state?.knowledgeBank);

    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        dispatch(fetchKnowledgeBank());
        dispatch(checkDocumentStatus());
    }, []);

    useEffect(() => {
        setRows(knowledgeBank);
    }, [knowledgeBank]);

    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => row.id === id ? { ...row, [field]: value } : row);
        setRows(updatedRows);
    };

    const handleNewRowChange = (field, value) => {
        setNewRow({ ...newRow, [field]: value, question_language: language });
    };

    const addRow = () => {
        setRows([...rows, newRow]);
        setNewRow({ id: Date.now(), question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
        onClose();
    };

    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.id !== id);
        setRows(updatedRows);
    };

    const deleteChild = (id) => {
        const updatedRows = rows.map(row => ({
            ...row,
            children: row.children.filter(child => child.id !== id)
        }));
        setRows(updatedRows);
    };

    const saveKnowledgeBank = () => {
        console.log(rows);
        dispatch(createKnowledgeBank(rows, toast));
    };

    const addChild = (id) => {
        const updatedRows = rows.map(row =>
            row.id === id && row.question_type === 'tree' && row.question_language === language ? { ...row, children: [...row.children, { id: Date.now(), question_type: 'horizontal', text: '', answer_text: '', speaker: '' }] } : row
        );
        setRows(updatedRows);
    };

    const handleChildInputChange = (parentId, childId, field, value) => {
        const updatedRows = rows.map(row =>
            row.id === parentId ? {
                ...row,
                children: row.children.map(child => child.id === childId ? { ...child, [field]: value } : child)
            } : row
        );
        setRows(updatedRows);
    };

    const toggleLanguage = () => {
        // setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'ar' : 'en'));
    };

    const handleConnectDocument = () => {
        dispatch(connectKnowledgeBank());
    };

    const handleCloseModal = () => {
        // Clear the states when closing the modal without saving
        setNewRow({ id: '', question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
        onClose(); // Call the onClose prop to close the modal
    };

    const filteredRows = rows.filter(row => row.question_language === language);
    console.log("🚀 ~ KnowledgeBank ~ rows:", rows)
    console.log("🚀 ~ KnowledgeBank ~ filteredRows:", filteredRows)

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
        
        if (file && allowedTypes.includes(file.type)) {
            setFileName(file.name);
        } else {
            toast({
                title: "Invalid file type",
                description: "Please upload a PDF, DOC, DOCX, or TXT file",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            setFileName('');
        }
    };

    const handleUpload = async () => {
        if (!fileName) {
            toast({
                title: "No file selected",
                description: "Please select a file to upload",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setIsUploading(true);
        const formData = new FormData();
        const file = fileInputRef.current.files[0];
        formData.append('file', file);
        
        // Check if file is actually appended
        console.log("File name:", file.name);
        console.log("File size:", file.size);
        console.log("File type:", file.type);

        try {
            const result = await dispatch(uploadKnowledgeBankFile(formData));
            console.log("Upload result:", result);
            dispatch(checkDocumentStatus());
            toast({
                title: "File uploaded",
                description: `${fileName} has been uploaded and processed successfully`,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: 'top',
            });

            // Reset the file inputs
            setFileName('');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            console.error('Upload error:', error);
            toast({
                title: "Upload failed",
                description: "There was an error uploading the file. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <Box>
            <Card
                backgroundRepeat='no-repeat'
                bgSize='cover'
                alignSelf='center'
                w={{ base: "100%", md: "60%", xl: "99%" }}
                bgPosition='10%'
                mx='auto'
                p='20px'
                mb={2}
                mt={20}>
                <VStack spacing={4} align="stretch">
                    <Text fontSize="xl" fontWeight="bold">{translations[language].uploadDocument}</Text>
                    <Input
                        type="file"
                        accept=".pdf,.doc,.docx,.txt"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        display="none"
                    />
                    <Button onClick={() => fileInputRef.current.click()}>
                        {translations[language].chooseFile}
                    </Button>
                    {fileName && <Text>{fileName}</Text>}
                    <Button
                        onClick={handleUpload}
                        colorScheme="blue"
                        isDisabled={!fileName || isUploading}
                        isLoading={isUploading}
                        loadingText="Uploading..."
                    >
                        {translations[language].uploadFile}
                    </Button>
                </VStack>
            </Card>
            <Card
                backgroundRepeat='no-repeat'
                bgSize='cover'
                alignSelf='center'
                w={{ base: "100%", md: "60%", xl: "99%" }}
                bgPosition='10%'
                mx='auto'
                p='20px'
                mb={1}>
                <Box display={{ sm: "none", lg: "flex" }} spacing='12px' mt={4} mb={4} >
                    {/* <Button onClick={toggleLanguage} mb={4} 
                bg="#F69320" 
                color="white" 
                _hover={{ bg: "#F67332" }}
                className={language === 'ar' && "arabic_font"}
                >
                    {translations[language].txtToggleButton}
                </Button> */}
                    <Button onClick={onOpen}
                        bg="#EF4959"
                        color="white"
                        _hover={{ bg: "#E43D50" }}
                        ml="auto" disabled={loading}
                        className={language === 'ar' && "arabic_font"}
                    >{translations[language].addRow}</Button>
                    <Button onClick={handleConnectDocument}
                        bg="#EF4959"
                        color="white"
                        _hover={{ bg: "#E43D50" }}
                        ml="auto" disabled={!isAvailable.is_available || checkDocumentStatusLoading}
                        className={language === 'ar' && "arabic_font"}
                    >{translations[language].connect}</Button>
                </Box>
                {filteredRows.map((row, index) => (
                    (row.question_type == 'tree' || row.question_type == 'simple') ? (
                        <Box key={row.id} mb={4} border="1px" borderColor="gray.300" p={4} borderRadius="md">
                            <Flex gap={6} alignItems="center">
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                    <Input
                                        className={language === 'ar' && "arabic_font"}
                                        value={row.text}
                                        onChange={(e) => handleInputChange(row.id, 'text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                    <Input
                                        className={language === 'ar' && "arabic_font"}
                                        value={row.answer_text}
                                        onChange={(e) => handleInputChange(row.id, 'answer_text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                {row.question_type === 'tree' && (
                                    <IconButton
                                        size="sm"
                                        aria-label="Add Child"
                                        icon={<AddIcon />}
                                        onClick={() => addChild(row.id)}
                                        colorScheme="teal"
                                    />
                                )}
                                <IconButton
                                    size="sm"
                                    aria-label="Delete row"
                                    icon={<DeleteIcon />}
                                    onClick={() => deleteRow(row.id)}
                                    colorScheme="red"
                                />
                            </Flex>
                            {row.children && row.children.map((child, childIndex) => (
                                <Flex key={child.id} gap={6} alignItems="center" ml={10} mt={4}>
                                    <FormControl>
                                        <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                        <Input
                                            value={child.text}
                                            onChange={(e) => handleChildInputChange(row.id, child.id, 'text', e.target.value)}
                                            maxLength={250}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                        <Input
                                            value={child.answer_text}
                                            onChange={(e) => handleChildInputChange(row.id, child.id, 'answer_text', e.target.value)}
                                            maxLength={250}
                                        />
                                    </FormControl>
                                    <IconButton
                                        size="sm"
                                        aria-label="Delete child row"
                                        icon={<DeleteIcon />}
                                        onClick={() => deleteChild(child.id)}
                                        colorScheme="red"
                                    />
                                </Flex>
                            ))}
                        </Box>
                    ) : (
                        <Box key={index} mb={4}>
                            {row.children && row.children.map((child, childIndex) => (
                                <Flex key={child.id} gap={6} alignItems="center" ml={10} mt={4}>
                                    <FormControl>
                                        <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                        <Input
                                            value={child.text}
                                            onChange={(e) => handleChildInputChange(row.id, child.id, 'text', e.target.value)}
                                            maxLength={250}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                        <Input
                                            value={child.answer_text}
                                            onChange={(e) => handleChildInputChange(row.id, child.id, 'answer_text', e.target.value)}
                                            maxLength={250}
                                        />
                                    </FormControl>
                                    <IconButton
                                        size="sm"
                                        aria-label="Delete child row"
                                        icon={<DeleteIcon />}
                                        onClick={() => deleteChild(child.id)}
                                        colorScheme="red"
                                    />
                                </Flex>
                            ))}
                        </Box>
                    )
                ))}

                <HStack display={{ sm: "none", lg: "flex" }} spacing='12px' mt={4}>
                    <Button onClick={saveKnowledgeBank} ml={1}
                        bg="#F69320"
                        color="white"
                        _hover={{ bg: "#F67332" }}
                        className={language === 'ar' && "arabic_font"}
                        disabled={loading}>{translations[language].save}</Button>
                </HStack>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className={language === 'ar' && "arabic_font"}
                            style={language === 'ar' ? { textAlign: 'right', marginRight: '25px' } : {}}
                        >{translations[language].addNewRow}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl>
                                <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].selectQuestionType}</FormLabel>
                                <Select
                                    value={newRow.question_type}
                                    onChange={(e) => handleNewRowChange('question_type', e.target.value)}
                                >
                                    <option value="" className={language === 'ar' && "arabic_font"} disabled>{translations[language].selectQuestionType}</option>
                                    <option value="simple" className={language === 'ar' && "arabic_font"}>{translations[language].single}</option>
                                    <option value="tree" className={language === 'ar' && "arabic_font"}>{translations[language].tree}</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                <Input
                                    className={language === 'ar' && "arabic_font"}
                                    textAlign={language === 'ar' ? 'right' : 'left'}
                                    value={newRow.text}
                                    onChange={(e) => handleNewRowChange('text', e.target.value)}
                                    maxLength={250}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                <Input
                                    className={language === 'ar' && "arabic_font"}
                                    textAlign={language === 'ar' ? 'right' : 'left'}
                                    value={newRow.answer_text}
                                    onChange={(e) => handleNewRowChange('answer_text', e.target.value)}
                                    maxLength={250}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                bg="#F69320"
                                color="white"
                                _hover={{ bg: "#F67332" }}
                                mr={3} onClick={addRow}
                                disabled={!newRow.question_type || !newRow.text || !newRow.answer_text}
                                className={language === 'ar' && "arabic_font"}
                            >
                                {translations[language].save}
                            </Button>
                            <Button variant="ghost" onClick={handleCloseModal} className={language === 'ar' && "arabic_font"}>
                                {translations[language].cancel}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Card>
        </Box>
    );
};

export default KnowledgeBank;