import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Select,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Box,
    HStack,
    useToast,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from 'react-redux';
import { fetchGeneralKnowledgeBank, createGeneralKnowledgeBank } from 'actions/generalKnowledgeBankAction';
import { useProfile } from 'context/CompanyContext';
import { LanguageContext } from 'context/LanguageContext';

const translations = {
    en: {
        question: "Question",
        answer: "Answer",
        addRow: "Add Question!",
        save: "Save",
        addNewRow: "Add New Question",
        selectQuestionType: "Select Question Type",
        single: "Single",
        tree: "Tree",
        cancel: "Cancel",
        connect: "Connect",
        txtToggleButton: "English Language"
    },
    ar: {
        question: "سؤال",
        answer: "إجابة",
        addRow: "أضف صفًا!",
        save: "حفظ",
        addNewRow: "إضافة صف جديد",
        selectQuestionType: "اختر نوع السؤال",
        single: "فردي",
        tree: "شجرة",
        cancel: "إلغاء",
        connect: "يتصل",
        txtToggleButton: "اللغة العربية"
    }
};

const GeneralKnowledgeBase = () => {
    const { profile } = useProfile();
    // const [language, setLanguage] = useState('ar');
    const { translate, language } = useContext(LanguageContext);
    const [rows, setRows] = useState([]);
    const [newRow, setNewRow] = useState({ id: '', question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();
    const toast = useToast();
    const { generalKnowledgeBank, loading, } = useSelector((state) => state?.generalKnowledgeBank);

    useEffect(() => {
        dispatch(fetchGeneralKnowledgeBank(profile.id));
    }, []);

    useEffect(() => {
        setRows(generalKnowledgeBank);
    }, [generalKnowledgeBank]);

    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => row.id === id ? { ...row, [field]: value } : row);
        setRows(updatedRows);
    };

    const handleNewRowChange = (field, value) => {
        setNewRow({ ...newRow, [field]: value, question_language: language });
    };

    const addRow = () => {
        setRows([...rows, newRow]);
        setNewRow({id: Date.now(), question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
        onClose();
    };

    const handleCloseModal = () => {
        // Clear the states when closing the modal without saving
        setNewRow({ id: '', question_type: '', text: '', answer_text: '', speaker: '', children: [], question_language: language });
        onClose(); // Call the onClose prop to close the modal
      };

    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.id !== id);
        setRows(updatedRows);
    };

    const deleteChild = (id) => {
        const updatedRows = rows.map(row => ({
            ...row,
            children: row.children.filter(child => child.id !== id)
        }));
        setRows(updatedRows);
    };

    const saveKnowledgeBank = async () => {
        const data = {"questions": rows, "general_knowledge_base_company": profile.id}
        try {
            const response = await dispatch(createGeneralKnowledgeBank(data, profile.id));
            toast({
              title: 'Success!',
              description: 'Knowledge Bank has been saved successfully.',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          } catch (err) {
            console.log("🚀 ~ saveKnowledgeBank ~ err:", err);
            toast({
              title: 'Error',
              description: 'Failed to save Knowledge Bank. Please try again.',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          }
    };

    const addChild = (id) => {
        const updatedRows = rows.map(row => 
            row.id === id && row.question_type === 'tree' && row.question_language === language ? { ...row, children: [...row.children, { id: Date.now(), question_type: 'horizontal', text: '', answer_text: '', speaker: '' }] } : row
        );
        setRows(updatedRows);
    };

    const handleChildInputChange = (parentId, childId, field, value) => {
        const updatedRows = rows.map(row => 
            row.id === parentId ? {
                ...row,
                children: row.children.map(child => child.id === childId ? { ...child, [field]: value } : child)
            } : row
        );
        setRows(updatedRows);
    };

    const toggleLanguage = () => {
        // setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'ar' : 'en'));
    };

    const filteredRows = rows.filter(row => row.question_language === language);

    return (
        <Box mx="auto" p={6} borderWidth={1} borderRadius="lg" bg="white">
            <Box display={{ sm: "none", lg: "flex" }} spacing='12px' mt={4} mb={4} >
                {/* <Button onClick={toggleLanguage} mb={4} 
                bg="#F69320" 
                color="white" 
                _hover={{ bg: "#F67332"}}
                className={language === 'ar' && "arabic_font"}
                >
                    {translations[language].txtToggleButton}
                </Button> */}
                <Button onClick={onOpen} 
                bg="#EF4959" 
                color="white" 
                _hover={{ bg: "#E43D50" }}
                ml="auto" disabled={loading} className={language === 'ar' && "arabic_font"}>{translations[language].addRow}</Button>
                {/* <Button onClick={handleConnectDocument} colorScheme="blue" ml="auto" disabled={!isAvailable.is_available || checkDocumentStatusLoading}>{translations[language].connect}</Button> */}
            </Box>
            {filteredRows.map((row, index) => (
                (row.question_type == 'tree' || row.question_type == 'simple') ? (
                    <Box key={row.id} mb={4} border="1px" borderColor="gray.300" p={4} borderRadius="md">
                        <Flex gap={6} alignItems="center">
                            <FormControl>
                                <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                <Input
                                    value={row.text}
                                    onChange={(e) => handleInputChange(row.id, 'text', e.target.value)}
                                    maxLength={250}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                <Input
                                    value={row.answer_text}
                                    onChange={(e) => handleInputChange(row.id, 'answer_text', e.target.value)}
                                    maxLength={250}
                                />
                            </FormControl>
                            {row.question_type === 'tree' && (
                                <IconButton
                                    size="sm"
                                    aria-label="Add Child"
                                    icon={<AddIcon />}
                                    onClick={() => addChild(row.id)}
                                    colorScheme="teal"
                                />
                            )}
                            <IconButton
                                size="sm"
                                aria-label="Delete row"
                                icon={<DeleteIcon />}
                                onClick={() => deleteRow(row.id)}
                                colorScheme="red"
                            />
                        </Flex>
                        {row.children && row.children.map((child, childIndex) => (
                            <Flex key={child.id} gap={6} alignItems="center" ml={10} mt={4}>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                    <Input
                                        value={child.text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                    <Input
                                        value={child.answer_text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'answer_text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <IconButton
                                    size="sm"
                                    aria-label="Delete child row"
                                    icon={<DeleteIcon />}
                                    onClick={() => deleteChild(child.id)}
                                    colorScheme="red"
                                />
                            </Flex>
                        ))}
                    </Box>
                ) : (
                    <Box key={index} mb={4}>
                        {row.children && row.children.map((child, childIndex) => (
                            <Flex key={child.id} gap={6} alignItems="center" ml={10} mt={4}>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                                    <Input
                                        value={child.text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                                    <Input
                                        value={child.answer_text}
                                        onChange={(e) => handleChildInputChange(row.id, child.id, 'answer_text', e.target.value)}
                                        maxLength={250}
                                    />
                                </FormControl>
                                <IconButton
                                    size="sm"
                                    aria-label="Delete child row"
                                    icon={<DeleteIcon />}
                                    onClick={() => deleteChild(child.id)}
                                    colorScheme="red"
                                />
                            </Flex>
                        ))}
                    </Box>
                )
            ))}

            <HStack display={{ sm: "flex", lg: "flex" }} spacing='12px' mt={4}>
                <Button onClick={saveKnowledgeBank} ml={1} 
                bg="#F69320" 
                color="white" 
                _hover={{ bg: "#F67332"}}
                disabled={loading}
                className={language === 'ar' && "arabic_font"}
                >{translations[language].save}</Button>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader 
                    style={language === 'ar' ? { textAlign: 'right', marginRight: '25px' } : {}}
                    className={language === 'ar' && "arabic_font"}>{translations[language].addNewRow}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].selectQuestionType}</FormLabel>
                            <Select
                                value={newRow.question_type}
                                onChange={(e) => handleNewRowChange('question_type', e.target.value)}
                            >
                                <option value="" disabled className={language === 'ar' && "arabic_font"}>{translations[language].selectQuestionType}</option>
                                <option value="simple" className={language === 'ar' && "arabic_font"}>{translations[language].single}</option>
                                <option value="tree" className={language === 'ar' && "arabic_font"}>{translations[language].tree}</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].question}</FormLabel>
                            <Input
                            className={language === 'ar' && "arabic_font"}
                            textAlign={language === 'ar' ? 'right' : 'left'}
                                value={newRow.text}
                                onChange={(e) => handleNewRowChange('text', e.target.value)}
                                maxLength={250}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel className={language === 'ar' && "arabic_font"}>{translations[language].answer}</FormLabel>
                            <Input
                            className={language === 'ar' && "arabic_font"}
                            textAlign={language === 'ar' ? 'right' : 'left'}
                                value={newRow.answer_text}
                                onChange={(e) => handleNewRowChange('answer_text', e.target.value)}
                                maxLength={250}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                        bg="#F69320" 
                        color="white" 
                        _hover={{ bg: "#F67332"}}
                         mr={3} onClick={addRow}
                         disabled={!newRow.question_type ||!newRow.text ||!newRow.answer_text}
                         className={language === 'ar' && "arabic_font"}
                         >
                            {translations[language].save}
                        </Button>
                        <Button variant="ghost" onClick={handleCloseModal} className={language === 'ar' && "arabic_font"}>
                            {translations[language].cancel}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default GeneralKnowledgeBase;


