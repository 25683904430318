import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Input, FormControl, FormLabel, VStack, useToast, IconButton } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useDispatch, useSelector } from 'react-redux';
import { uploadTutorial, fetchTutorial } from 'actions/tutorialAction'; // Import your action functions
import Card from "components/card/Card";
import { LanguageContext } from 'context/LanguageContext';
import LineSpinnerComponent from 'components/spinner';

const TextEditorComponent = () => {
  const [tutorial, setTutorial] = useState(null);
  const [title, setTitle] = useState('');
  const [textContent, setTextContent] = useState('');
  const [mediaFile, setMediaFile] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  const { tutorials, loading } = useSelector((state) => state.tutorials);
  const { translate, language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchData = async () => {
      const tutorialResponse = await dispatch(fetchTutorial());
      const lastTutorial = tutorialResponse[tutorialResponse.length - 1];
      setTutorial(lastTutorial);
    };

    fetchData();
  }, [dispatch]);

  async function getFileFromURL(url) {
    try {
      // Fetch the file from the URL
      const response = await fetch(url);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }

      // Get the file as a Blob
      const blob = await response.blob();

      // Create a File instance from the Blob
      const file = new File([blob], tutorial?.media_file.split('/').pop() || '', {
        type: blob.type,
        lastModified: new Date().getTime(), // Optional: set the last modified time
      });

      return file;
    } catch (error) {
      console.error('Error fetching the file:', error);
    }
  }

  // Use effect to update the fields when tutorial data changes
  useEffect(() => {
    if (tutorial) {
      setTitle(tutorial.title || '');
      setTextContent(tutorial.text_content || '');
      getFileFromURL(tutorial?.media_file).then(fileInstance => {
        if (fileInstance) {
          console.log('File instance:', fileInstance);
          setMediaFile(fileInstance);
          // You can now use the file instance as needed
        }
      })
        .catch((err) => {
          console.log("🚀 ~ useEffect ~ err:", err)
        })

      // setMediaFile(tutorial.media_file || '');
    }
  }, [tutorial]);

  const handleFileChange = (e) => {
    setMediaFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (title && textContent && mediaFile) {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('text_content', textContent);
      formData.append('media_file', mediaFile);
      try {
        const response = await dispatch(uploadTutorial(formData));
        toast({
          title: 'Success!',
          description: 'Tutorial uploaded successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } catch (err) {
        console.log("🚀 ~ handleSubmit ~ err:", err);
        toast({
          title: 'Error!',
          description: 'Failed to upload tutorial. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } else {
      toast({
        title: 'Warning',
        description: 'Please fill in all fields and upload a file.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <>
    {
      loading ? <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <LineSpinnerComponent />
      </Box> : <Box>
      <Card mt="80px">
      <VStack spacing={4}>
        <FormControl>
          <FormLabel htmlFor="title" className={language === 'ar' && "arabic_font"}>{translate("title")}</FormLabel>
          <Input
            className={language === 'ar' && "arabic_font"}
            id="title"
            placeholder={translate("title")}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="textEditor" className={language === 'ar' && "arabic_font"}>{translate("text-content")}</FormLabel>
          <ReactQuill
            id="textEditor"
            value={textContent}
            onChange={setTextContent}
            modules={TextEditorModules}
            style={{ height: '300px', width: '100%' }} // Set the desired height and width
            className={language === 'ar' ? 'rtl-quill' : ''} // Add this line
          />
        </FormControl>
        <FormControl>
          <FormLabel mt="40px" htmlFor="mediaFile" className={language === 'ar' && "arabic_font"}>
            {translate("upload-media")}
          </FormLabel>

          {mediaFile ? (
            // If a file already exists, display the file name and an upload icon
            <Box display="flex" alignItems="center">
              <Box fontWeight="bold" mr={2}>
                {mediaFile.name || ''} {/* Display the file name */}
              </Box>
              <IconButton
                aria-label="Upload New File"
                icon={<FiUpload />}
                onClick={() => document.getElementById('mediaFileInput').click()} // Trigger file input click
              />
              {/* Hidden input for uploading a new file */}
              <Input
                id="mediaFileInput"
                type="file"
                accept="image/*,video/*"
                onChange={handleFileChange}
                display="none" // Hide the actual input
              />
            </Box>
          ) : (
            // If no file exists, show the regular file input
            <Input
              id="mediaFile"
              type="file"
              accept="image/*,video/*"
              onChange={handleFileChange}
            />
          )}
        </FormControl>

        <Button
          isDisabled={loading}
          bg="#F69320"
          color="white"
          _hover={{ bg: "#F67332" }}
          onClick={handleSubmit}
          className={language === 'ar' && "arabic_font"}
        >
          {translate("submit")}
        </Button>
      </VStack>
    </Card>
      </Box>
    }
    </>
  );
};

const TextEditorModules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    [{ 'align': [] }],
    ['link', 'image'],
  ],
};

export default TextEditorComponent;
