import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  SimpleGrid,
  Text,
  FormErrorMessage,
  Flex,
  useToast,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ColumnsTable from "views/admin/company/components/ColumnsTable";
// import { columnsDataColumns } from "views/admin/company/variables/columnsData";
import { fetchUsers, createUser } from "actions/userActions";
import { fetchCompanyAccounts } from "actions/accountAction";
import { fetchCompanyProfiles } from "actions/profileAction";
import LineSpinnerComponent from 'components/spinner';
import styled from 'styled-components';
import { LanguageContext } from "context/LanguageContext";
import { format, addDays } from 'date-fns';

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 8px 12px;  /* Ensure this matches the padding of the select input */
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  color: #4a5568;
  box-sizing: border-box; /* This ensures the padding is included within the width */
  
  &:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }

  &::placeholder {
    color: #a0aec0;
  }
`;

// Function to generate columns with translated headers
export const getColumnsData = (translate) => [
  {
    Header: translate("column-header-comp-name"),
    accessor: "company_name",
  },
  {
    Header: translate("column-header-web-url"),
    accessor: "website",
  },
  {
    Header: translate("column-header-web-url-ar"),
    accessor: "website_ar",
  },
  {
    Header: translate("column-header-size"),
    accessor: "size",
  },
  {
    Header: translate("column-header-subscription-type"),
    accessor: "subscription_type",
  },
  {
    Header: translate("column-header-subscription-period"),
    accessor: "subscription_time",
  },
];

function Company() {
  const {
    isOpen: isCompanyModalOpen,
    onOpen: onCompanyModalOpen,
    onClose: onCompanyModalClose
  } = useDisclosure();

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { translate, language } = useContext(LanguageContext);
  const columnsData = getColumnsData(translate);

  const [name, setName] = useState('');
  const [size, setSize] = useState('');
  const [website, setWebsite] = useState('');
  const [website_ar, setWebsite_ar] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [subscriptionTime, setSubscriptionTime] = useState('');
  const [errors, setErrors] = useState({});

  const initialRef = React.useRef(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const { users, loading } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCompanyProfiles());
    dispatch(fetchCompanyAccounts());
  }, [dispatch]);

  const validateFields = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Company name is required';
    if (!size) newErrors.size = 'Company size is required';
    if (!website) newErrors.website = 'Website is required';
    if (!website_ar) newErrors.website_ar = 'Website in Arabic is required';
    if (!subscriptionType) newErrors.subscriptionType = 'Subscription type is required';
    if (!subscriptionTime) newErrors.subscriptionTime = 'Subscription time is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateCompany = async () => {
    if (!validateFields()) {
      return;
    }

    const company = {
      company_name: name,
      size,
      website,
      website_ar,
      subscription_type: subscriptionType,
      subscription_time: subscriptionTime,
    };

    try {
      await dispatch(createUser(company));
      setSize('');
      setWebsite('');
      setName('');
      setWebsite_ar('');
      setSubscriptionType('');
      setSubscriptionTime(addDays(new Date(), 1));
      onCompanyModalClose();
      toast({
        title: 'Company created successfully',
        description: 'Company account created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } catch (err) {
      console.log("🚀 ~ handleCreateCompany ~ err:", err);
      toast({
        title: 'An error occurred while creating company',
        description: 'Unable to create company account. Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <>
      <div>
        <Flex justifyContent="flex-end" mt='80px'>
          <Button
            bg="#F69320"
            color="white"
            _hover={{ bg: "#F67332" }}
            onClick={onCompanyModalOpen}
            className={`modal-btn ${language === 'ar' && "arabic_font"}`}
          >
            {translate("btn-create-company")}
          </Button>
        </Flex>
        <Modal
          initialFocusRef={initialRef}
          isOpen={isCompanyModalOpen}
          onClose={onCompanyModalClose}
        >
          <ModalOverlay />
          <ModalContent maxW="900px">
            <ModalHeader  //style={language === 'ar' ? { textAlign: 'right', marginRight: '15px' } : {}} 
            className={language === 'ar' && "arabic_font"}
            style={language === 'ar' ? { textAlign: 'right', marginRight: '25px' } : {}}
            >{translate("txt-create-company")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={errors.name}>
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                >{translate("txt-comp-name")}<Text color={brandStars}>*</Text></FormLabel>
                <Input
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                className={language === 'ar' && "arabic_font"}
                  isRequired
                  ref={initialRef}
                  placeholder={translate("txt-comp-name")}
                  value={name}
                  onChange={(e) => {
                    setErrors({...errors, name: ''});
                    setName(e.target.value)}}
                />
                {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={errors.size}>
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                >{translate("txt-comp-size")}<Text color={brandStars}>*</Text></FormLabel>
                <Input
                className={language === 'ar' && "arabic_font"}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                  isRequired
                  type="number"
                  placeholder={translate("txt-comp-size")}
                  value={size}
                  onChange={(e) => {
                    setErrors({...errors, size: ''});
                    setSize(e.target.value)}}
                />
                {errors.size && <FormErrorMessage>{errors.size}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={errors.website}>
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                >{translate("txt-comp-web")}<Text color={brandStars}>*</Text></FormLabel>
                <Input
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                className={language === 'ar' && "arabic_font"}
                  isRequired
                  type="email"
                  placeholder={translate("txt-comp-web")}
                  value={website}
                  onChange={(e) => {
                    setErrors({...errors, website: ''});
                    setWebsite(e.target.value)}}
                />
                {errors.website && <FormErrorMessage>{errors.website}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={errors.website_ar}>
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                >{translate("txt-comp-web-ar")}<Text color={brandStars}>*</Text></FormLabel>
                <Input
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                className={language === 'ar' && "arabic_font"}
                  isRequired
                  type="email"
                  placeholder={translate("txt-comp-web-ar")}
                  value={website_ar}
                  onChange={(e) => {
                    setErrors({...errors, website_ar: ''});
                    setWebsite_ar(e.target.value)}}
                />
                {errors.website_ar && <FormErrorMessage>{errors.website_ar}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={errors.subscriptionType}>
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                >{translate("txt-comp-sub-type")}<Text color={brandStars}>*</Text></FormLabel>
                <Box position="relative">
                  <Select
                    dir={language === 'ar' ? 'rtl' : 'ltr'}
                    className={`hidden-arrow ${language === 'ar' ? "arabic_font" : ""}`}
                    placeholder={translate("txt-comp-sub-type")}
                    value={subscriptionType}
                    onChange={(e) => {
                      setErrors({...errors, subscriptionType: ''});
                      setSubscriptionType(e.target.value)
                    }}
                    sx={{
                      '& > option': {
                        color: 'black',
                      },
                    }}
                    iconSize="0"
                  >
                    <option value="monthly" className={language === 'ar' && "arabic_font"}>{translate("txt-comp-sub-type-monthly")}</option>
                    <option value="yearly" className={language === 'ar' && "arabic_font"}>{translate("txt-comp-sub-type-yearly")}</option>
                  </Select>
                </Box>
                {errors.subscriptionType && <FormErrorMessage>{errors.subscriptionType}</FormErrorMessage>}
              </FormControl>


              <FormControl mt={4} isInvalid={errors.subscriptionTime}>
                <FormLabel display="flex" className={language === 'ar' && "arabic_font"}
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                >
                {translate("txt-comp-sub-time")}<Text color={brandStars}>*</Text>
                </FormLabel>
                <StyledDatePicker
                dir={language === 'ar' ? 'rtl' : 'ltr'}
                className={language === 'ar' ? "arabic_font" : ""}
                  selected={subscriptionTime}
                  onChange={(date) => {
                    setErrors({...errors, subscriptionTime: ''});
                    setSubscriptionTime(format(date, 'yyyy-MM-dd'))}}
                    minDate={addDays(new Date(), 1)} // Disables today's and past dates
                  dateFormat="yyyy-MM-dd"
                  placeholderText={translate("txt-comp-sub-time")}
                />
                {errors.subscriptionTime && (
                  <FormErrorMessage>{errors.subscriptionTime}</FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                bg="#F69320"
                color="white"
                _hover={{ bg: "#F67332" }}
                mr={3}
                onClick={handleCreateCompany}
                className={language === 'ar' && "arabic_font"}
              >
                {translate("txt-comp-create")}
              </Button>
              <Button onClick={onCompanyModalClose} className={language === 'ar' && "arabic_font"}>{translate("txt-comp-cancel")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box pt={{ base: "50px", md: "10px", xl: "20px" }}>
          <SimpleGrid
            display="block"
            mb='20px'
            spacing={{ base: "20px", xl: "20px" }}
          >
            {loading ? <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
              <LineSpinnerComponent />
            </Box> : users && <ColumnsTable
              columnsData={columnsData}
              tableData={users}
              isCompanyTable={true}
            />}
          </SimpleGrid>
        </Box>
      </div>
    </>
  );
}

export default Company;