import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, VStack, useToast } from "@chakra-ui/react";
import { StepOne, StepTwo, StepThree, StepFour, StepFive } from './components';
import { useDispatch } from 'react-redux';
import { createConvaiCharacter } from 'actions/avatarActions';
import { fetchAvatar, fetchConvaiCharacter, updateConvaiCharacter } from 'actions/avatarActions';
import { useSelector } from'react-redux';   
import { useHistory } from'react-router-dom';

const MultiStepForm = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const [step, setStep] = useState(1);
    const avatarData = useSelector(state => state?.avatar?.avatar);
    const convAI = useSelector(state => state?.avatar?.characterDetail);
    const history = useHistory();
    const [formData, setFormData] = useState({
        character_name: convAI[0]?.character_information?.character_name || '',
        character_ar_name: convAI[0]?.character_ar_name || '',
        character_backstory: convAI[0]?.backstory || '',
        character_ar_backstory: convAI[0]?.character_information?.character_ar_backstory || '',
        catch_phrase: convAI[0]?.character_catch_phrase || '',
        catch_phrase_ar: convAI[0]?.character_ar_catch_phrase || '',
        character_voice: convAI[0]?.character_voice || '',
        character_voice_ar: convAI[0]?.character_voice_ar || '',
        avatar_url: '',
        avatarId: '',
        companyInfo: '',
        character_language_code: convAI[0]?.character_information?.language_code || '',
        avatar_placeholder: '',
        character_knowledge_bank: '',
        avatar_model_type: '',
    });

    useEffect(() => {
        dispatch(fetchAvatar());
        dispatch(fetchConvaiCharacter());
    }, [dispatch]);

    useEffect(() => {
        setFormData({...formData, 
            character_name: convAI[0]?.character_information?.character_name,
            character_ar_name: convAI[0]?.character_ar_name,
            character_backstory: convAI[0]?.character_information?.backstory,
            character_ar_backstory: convAI[0]?.character_ar_backstory,
            character_language_code: convAI[0]?.character_information?.language_code,
            character_voice: convAI[0]?.character_voice,
            character_voice_ar: convAI[0]?.character_voice_ar,
            catch_phrase: convAI[0]?.character_catch_phrase || '',
            catch_phrase_ar: convAI[0]?.character_ar_catch_phrase || '',
         });
        if(convAI?.length > 0) {
            toast({
                title: `You alredy have created avatar`,
                status: "success",
                isClosable: true,
                position: 'top',
            })
        }
    }, [convAI?.length > 0])

    const nextStep = () => {
        console.log('Form submitted:', formData);
        setStep(step + 1);
    }
    const prevStep = () => setStep(step - 1);
    const submitForm = () => {
        console.log('Form submitted:', formData);        // Handle form submission  

        const updatedFormData = {
            character_name: formData.character_name,
            character_backstory: formData.character_backstory,            
            character_voice: formData.character_voice,
            character_voice_ar: formData.character_voice_ar,
            // character_language_code: formData.character_language_code,
            character_language_code: formData.character_voice !== '' && formData.character_voice_ar !== '' ? 'en-ar' :  formData.character_language_code,
            // character_language_code: formData.character_language_code === 'en-US' ? formData.character_language_code : 'ar',
            avatar_url: formData.avatar_url || convAI[0]?.character_information?.model_details?.modelLink,
            avatar_placeholder: formData.avatar_placeholder || convAI[0]?.character_information?.model_details?.modelPlaceholder,
            avatar_model_type: formData.avatar_model_type || convAI[0]?.character_information?.model_details?.modelType,
            character_ar_name: formData.character_ar_name,
            character_ar_backstory: formData.character_ar_backstory,
            catch_phrase: formData.catch_phrase,
            catch_phrase_ar: formData.catch_phrase_ar,
            // character_knowledge_bank: `${formData.companyInfo}, ${formData.character_knowledge_bank}`,
         };
        console.log("🚀 ~ submitForm ~ updatedFormData:", updatedFormData)
        
         if(convAI?.length > 0){
            dispatch(updateConvaiCharacter({...updatedFormData, character_id: convAI[0]?.character_id}, toast, history));
         }
         else{
            dispatch(createConvaiCharacter(updatedFormData, toast, history));
         }
    };

    return (
        <Box>
            {step === 1 &&
                <Box mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white">
                    <StepOne formData={formData} setFormData={setFormData} nextStep={nextStep} submitForm={submitForm} />
                </Box>
            }
            {step === 2 &&
                <Box>
                    <StepTwo formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                </Box>
            }
            {/* {step === 3 &&
                <Box maxW="md" mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white">
                    <StepThree formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                </Box>} */}
            {/* {step === 3 &&
                <Box mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white" >
                    <StepFour formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                </Box>} */}
            {step === 3 &&
                <Box mx="auto" mt={20} p={6} borderWidth={1} borderRadius="lg" bg="white">
                    <StepFive formData={formData} setFormData={setFormData} prevStep={prevStep} submitForm={submitForm} />
                </Box>}
        </Box>
    );
};

export default MultiStepForm;
