import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box, Input, Textarea, Button, FormControl, FormLabel, useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  Flex,
  Heading,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { fetchProfileById, updateCompanyProfile } from 'actions/profileAction';
import { useDispatch, useSelector } from 'react-redux';
import { useProfile } from 'context/CompanyContext';
import { LanguageContext } from 'context/LanguageContext';
import Card from "components/card/Card.js";
import { uploadCompanyDocument } from 'actions/tutorialAction';

const translations = {
  en: {
    addQuestion: 'Add a new question',
    language: 'Language',
    question: 'Question',
    enterQuestion: 'Enter your question',
    answer: 'Answer',
    enterAnswer: 'Enter the answer',
    save: 'Save',
    cancel: 'Cancel',
    uploadDocument: 'Upload Document',
    chooseFile: 'Choose File',
    uploadFile: 'Upload File',
    },
  ar: {
    addQuestion: 'أضف سؤالًا جديدًا',
    language: 'اللغة',
    question: 'سؤال',
    enterQuestion: 'أدخل سؤالك',
    answer: 'إجابة',
    enterAnswer: 'أدخل الإجابة',
    save: 'حفظ',
    cancel: 'إلغاء',
    uploadDocument: 'تحميل الوثيقة',
    chooseFile: 'اختيار الملف',
    uploadFile: 'تحميل الملف',
  }
};

const ManageCompanyInfo = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { translate, language } = useContext(LanguageContext);
  const { profile, setProfile } = useProfile();
  // const [language, setLanguage] = useState('ar');
  const [companyData, setCompanyData] = useState({
    activities: '',
    foundation_details: '',
    founders: '',
    company_mission: '',
    company_revenue: '',
    company_certificates: '',
    company_projects: '',
    company_collaboration: '',
    company_commitments: '',
    company_challenges: '',
    extra_info: {},
    activities_ar: '',
    foundation_details_ar: '',
    founders_ar: '',
    company_mission_ar: '',
    company_revenue_ar: '',
    company_certificates_ar: '',
    company_projects_ar: '',
    company_collaboration_ar: '',
    company_commitments_ar: '',
    company_challenges_ar: '',
    is_active: true,
  });

  const user = useSelector((state) => state.companyProfiles?.singleProfile);
  const [extraInfo, setExtraInfo] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const t = translations[language];

  const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (profile) {
      const extraInfoTemp = profile.extra_info || {};
      const arrayresult = Object.entries(extraInfoTemp).map(([key, value]) => ({
        question: key,
        answer: value
      }));
      setCompanyData({
        activities: profile.activities || '',
        foundation_details: profile.foundation_details || '',
        founders: profile.founders || '',
        company_mission: profile.company_mission || '',
        company_revenue: profile.company_revenue || '',
        company_certificates: profile.company_certificates || '',
        company_projects: profile.company_projects || '',
        company_collaboration: profile.company_collaboration || '',
        company_commitments: profile.company_commitments || '',
        company_challenges: profile.company_challenges || '',
        activities_ar: profile.activities_ar || '',
        foundation_details_ar: profile.foundation_details_ar || '',
        founders_ar: profile.founders_ar || '',
        company_mission_ar: profile.company_mission_ar || '',
        company_revenue_ar: profile.company_revenue_ar || '',
        company_certificates_ar: profile.company_certificates_ar || '',
        company_projects_ar: profile.company_projects_ar || '',
        company_collaboration_ar: profile.company_collaboration_ar || '',
        company_commitments_ar: profile.company_commitments_ar || '',
        company_challenges_ar: profile.company_challenges_ar || '',
        is_active: true,
      });
      setExtraInfo(arrayresult);
    }
  }, [profile])

  useEffect(async () => {
    const profileId = profile?.id;
    try{
      const response = await dispatch(fetchProfileById(profileId));
    }
    catch(error){
      console.error('Failed to fetch user profile:', error);
    }
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log("language in handlesubmit", language);
    const objectresult = extraInfo.reduce((acc, current) => {
      acc[current.question] = current.answer;
      return acc;
    }, {});
    setCompanyData(prevData => ({
      ...prevData,
      extra_info: objectresult
    }));

    e.preventDefault();
    try {
      const profileId = profile?.id;
      const companyId = profile?.company;
      const response = await dispatch(updateCompanyProfile({ ...companyData, extra_info: objectresult, id: profileId, company: companyId }));  
      toast({
        title: 'Success',
        description: 'Profile has been updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
position: 'top',
      });  
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to update profile.',
        status: 'error',
        duration: 5000,
        isClosable: true,
position: 'top',
      });
    }
    
  };

  const handleSubmitModal = async () => {
    const newEntry = { question, answer };
    setExtraInfo([...extraInfo, newEntry]);
    setQuestion('');
    setAnswer('');
    onClose();

  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newExtraInfo = extraInfo.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setExtraInfo(newExtraInfo);
  };

  // const toggleLanguage = () => {
  //   console.log("language --->>> ", language);
  //   setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'ar' : 'en'));
  // };

  const handleCloseModal = () => {
    // Clear the states when closing the modal without saving
    setQuestion('');
    setAnswer('');
    onClose(); // Call the onClose prop to close the modal
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setFileName(file.name);
    } else {
        toast({
            title: "Invalid file type",
            description: 'Invalid file type. Only .xlsx files are allowed.',
            status: "error",
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
        setFileName('');
    }
};

const handleUpload = async () => {
    if (!fileName) {
        toast({
            title: "No file selected",
            description: "Please select a file to upload",
            status: "warning",
            duration: 3000,
            isClosable: true,
        });
        return;
    }

    setIsUploading(true);
    const formData = new FormData();
    const file = fileInputRef.current.files[0];
    formData.append('file', file);
    formData.append('company', profile?.company);
    
    // Check if file is actually appended
    console.log("File name:", file.name);
    console.log("File size:", file.size);
    console.log("File type:", file.type);

    try {
        const result = await dispatch(uploadCompanyDocument(formData));
        console.log("Upload result:", result);
        const fetchProfile = await dispatch(fetchProfileById(profile?.id));
        setProfile(fetchProfile);
        toast({
            title: "File uploaded",
            description: `${fileName} has been uploaded and processed successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: 'top',
        });

        // Reset the file inputs
        setFileName('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    } catch (error) {
        console.error('Upload error:', error);
        toast({
            title: "Upload failed",
            description: "There was an error uploading the file. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
    } finally {
        setIsUploading(false);
    }
};

  return (
    <Box>
      <Card
                backgroundRepeat='no-repeat'
                bgSize='cover'
                alignSelf='center'
                w={{ base: "100%", md: "60%", xl: "99%" }}
                bgPosition='10%'
                mx='auto'
                p='20px'
                mb={2}
                mt={4}
                borderWidth="1px"
                borderColor="gray.200"
                borderRadius="lg"
                boxShadow="sm">
                <VStack spacing={4} align="stretch">
                    <Text fontSize="xl" className={language === 'ar' ? "arabic_font" : ""} fontWeight="bold" textAlign={language === 'ar' ? 'right' : 'left'}>{t.uploadDocument}</Text>
                    <Input
                    className={language === 'ar' ? "arabic_font" : ""} 
                        type="file"
                        accept=".xlsx"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        display="none"
                    />
                    <Button className={language === 'ar' ? "arabic_font" : ""}  onClick={() => fileInputRef.current.click()}>
                        {t.chooseFile}
                    </Button>
                    {fileName && <Text>{fileName}</Text>}
                    <Button
                    className={language === 'ar' ? "arabic_font" : ""} 
                        onClick={handleUpload}
                        colorScheme="blue"
                        isDisabled={!fileName || isUploading}
                        isLoading={isUploading}
                        loadingText="Uploading..."
                    >
                        {t.uploadFile}
                    </Button>
                </VStack>
            </Card>
    <Box mx="auto" p={6} borderWidth={1} borderRadius="lg" bg="white">
      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel
          className={language === 'ar' && "english_font"}
          >What are the company’s activities?</FormLabel>
          <Input 
          className={language === 'ar' && "english_font"}
          name="activities" value={companyData.activities} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign="right" className={"arabic_font"}>ما هي أنشطة الشركة؟</FormLabel>
          <Input name="activities_ar" textAlign="right" value={companyData.activities_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>When was the company founded?</FormLabel>
          <Input className={language === 'ar' && "english_font"} name="foundation_details" placeholder='Asking for the year and how it started.' value={companyData.foundation_details} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign="right" className={"arabic_font"}>متى تأسست الشركة؟</FormLabel>
          <Input name="foundation_details_ar" textAlign="right" className={"arabic_font"} placeholder='السؤال عن السنة وكيف بدأت' value={companyData.foundation_details_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>Who are the founders and key personnel?</FormLabel>
          <Textarea className={language === 'ar' && "english_font"} name="founders" value={companyData.founders} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>من هم المؤسسون والعاملون الرئيسيون؟</FormLabel>
          <Textarea name="founders_ar" textAlign={"right"} value={companyData.founders_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>What are the company’s core values, vision, and mission?</FormLabel>
          <Textarea className={language === 'ar' && "english_font"} name="company_mission" value={companyData.company_mission} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"} >ما هي القيم الأساسية للشركة ورؤيتها ورسالتها؟</FormLabel>
          <Textarea name="company_mission_ar" textAlign={"right"} value={companyData.company_mission_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>What are the company’s annual revenues and profits?</FormLabel>
          <Input className={language === 'ar' && "english_font"} name="company_revenue" value={companyData.company_revenue} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي إيرادات وأرباح الشركة السنوية؟</FormLabel>
          <Input name="company_revenue_ar" textAlign={"right"} value={companyData.company_revenue_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>Does the company hold any certificates or awards?</FormLabel>
          <Textarea className={language === 'ar' && "english_font"} name="company_certificates" value={companyData.company_certificates} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>هل تحمل الشركة أي شهادات أو جوائز؟</FormLabel>
          <Textarea name="company_certificates_ar" textAlign={"right"} value={companyData.company_certificates_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>What are the latest innovations and projects the company is working on?</FormLabel>
          <Textarea className={language === 'ar' && "english_font"} name="company_projects" value={companyData.company_projects} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4} >
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي أحدث الابتكارات والمشاريع التي تعمل عليها الشركة؟</FormLabel>
          <Textarea name="company_projects_ar" textAlign={"right"} value={companyData.company_projects_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>What are the company’s strategic partnerships and collaborations?</FormLabel>
          <Textarea className={language === 'ar' && "english_font"} name="company_collaboration" value={companyData.company_collaboration} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي الشراكات والتعاون الاستراتيجي للشركة؟</FormLabel>
          <Textarea name="company_collaboration_ar" textAlign={"right"} value={companyData.company_collaboration_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>What are the company’s commitments to environmental and social issues?</FormLabel>
          <Textarea className={language === 'ar' && "english_font"} name="company_commitments" value={companyData.company_commitments} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>ما هي التزامات الشركة تجاه القضايا البيئية والاجتماعية؟</FormLabel>
          <Textarea name="company_commitments_ar" textAlign={"right"} value={companyData.company_commitments_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel className={language === 'ar' && "english_font"}>Are there any current challenges or issues facing the company?</FormLabel>
          <Textarea className={language === 'ar' && "english_font"} name="company_challenges" value={companyData.company_challenges} onChange={handleChange} maxLength={250} />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel textAlign={"right"} className={"arabic_font"}>هل هناك أي تحديات أو مشاكل حالية تواجه الشركة؟</FormLabel>
          <Textarea name="company_challenges_ar" textAlign={"right"} value={companyData.company_challenges_ar} onChange={handleChange} maxLength={250} />
        </FormControl>
        {extraInfo.map((item, index) => (
          <div key={index}>
            <FormLabel 
            dir='ltr'
            textAlign={language === 'ar' ? 'right' : 'left'} className={"arabic_font"}>{item.question}</FormLabel>
            <Textarea
            className={"arabic_font"}
              name="answer"
              value={item.answer}
              onChange={(e) => handleInputChange(index, e)}
              textAlign={language === 'ar' ? 'right' : 'left'}
              maxLength={250}
            />
          </div>
        ))}
        <HStack display={{ sm: "flex", lg: "flex" }} spacing='12px' mt={4}>
          <Button type="submit" mr={1.5}
            bg="#F69320"
            color="white"
            _hover={{ bg: "#F67332" }}
            className={language === 'ar' && "arabic_font"}
          >{translate("btn-save")}</Button>
          <Button onClick={onOpen}
            bg="#EF4959"
            color="white"
            _hover={{ bg: "#E43D50" }}
            className={language === 'ar' && "arabic_font"}
          >{translate("add-question")}</Button>
        </HStack>
      </form>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader 
          style={language === 'ar' ? { textAlign: 'right', marginRight: '25px' } : {}}
          className={language === 'ar' && "arabic_font"}>{t.addQuestion}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Flex justify="space-between" align="center" mb={6}>
              <Heading as="h5" size="sm" className={language === 'ar' && "arabic_font"}>
                {t.language}
              </Heading>
              
              <Switch
              name="is_active"
              isChecked={language === 'ar' ? true : false}
              onChange={toggleLanguage}
            />
            </Flex> */}
            <FormControl id="question" textAlign={language === 'ar' ? 'right' : 'left'}>
              <FormLabel textAlign={language === 'ar' ? 'right' : 'left'} className={language === 'ar' && "arabic_font"}>{t.question}</FormLabel>
              <Input
              className={language === 'ar' && "arabic_font"}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={t.enterQuestion}
                textAlign={language === 'ar' ? 'right' : 'left'}
                maxLength={250}
              />
            </FormControl>
            <FormControl id="answer" mt={4} textAlign={language === 'ar' ? 'right' : 'left'}>
              <FormLabel textAlign={language === 'ar' ? 'right' : 'left'} className={language === 'ar' && "arabic_font"}>{t.answer}</FormLabel>
              <Textarea
              className={language === 'ar' && "arabic_font"}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder={t.enterAnswer}
                textAlign={language === 'ar' ? 'right' : 'left'}
                maxLength={250}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button 
            bg="#F69320" 
            color="white" 
            _hover={{ bg: "#F67332"}}
            mr={3} onClick={handleSubmitModal}
            disabled={!question ||!answer}
            className={language === 'ar' && "arabic_font"}
            >
              {t.save}
            </Button>
            <Button variant="ghost" className={language === 'ar' && "arabic_font"} onClick={handleCloseModal}>{t.cancel}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
    </Box>
  );
};

export default ManageCompanyInfo;