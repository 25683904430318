import axiosInstance from '../axiosConfig';

export const fetchTutorial = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_TUTORIAL_REQUEST'
        });
        return axiosInstance.get('/common/tutorials/')
            .then(response => {
                const tutorials = response.data;
                console.log("🚀 ~ return ~ tutorials:", tutorials)
                dispatch({
                    type: 'FETCH_TUTORIAL_SUCCESS',
                    payload: tutorials
                });
                return tutorials;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'FETCH_TUTORIAL_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};

export const uploadTutorial = (formData) => {
    return (dispatch) => {
        dispatch({
            type: 'UPLOAD_TUTORIAL_REQUEST'
        });
        return axiosInstance.post('/common/tutorials/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
              },
        })
            .then(response => {
                const tutorialVideo = response.data;
                dispatch({
                    type: 'UPLOAD_TUTORIAL_SUCCESS',
                    payload: tutorialVideo
                });
                dispatch(fetchTutorial());
                return tutorialVideo;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'UPLOAD_TUTORIAL_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};

export const uploadKnowledgeBankFile = (formData) => {
    return (dispatch) => {
        dispatch({
            type: 'UPLOAD_KNOWLEDGE_BANK_FILE_REQUEST'
        });
        return axiosInstance.post('/avatars/questions/extract_text_from_file/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const knowledgeBankFileResponse = response.data;
                dispatch({
                    type: 'UPLOAD_KNOWLEDGE_BANK_FILE_SUCCESS',
                    payload: knowledgeBankFileResponse
                });
                // Removed dispatch(fetchTutorial());
                return knowledgeBankFileResponse;
            })
            .catch(error => {
                const errorMsg = error.response?.data?.message || error.message;
                dispatch({
                    type: 'UPLOAD_KNOWLEDGE_BANK_FILE_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};

export const uploadCompanyDocument = (formData) => {
    return (dispatch) => {
        dispatch({
            type: 'UPLOAD_COMPANY_DOCUMENT_REQUEST'
        });
        return axiosInstance.post('/companies/company_profiles/import_profile/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const companyDocument = response.data;
                dispatch({
                    type: 'UPLOAD_COMPANY_DOCUMENT_SUCCESS',
                    payload: companyDocument
                });
                return companyDocument;
            })
            .catch(error => {
                const errorMsg = error.response?.data?.message || error.message;
                dispatch({
                    type: 'UPLOAD_COMPANY_DOCUMENT_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};
