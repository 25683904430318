// reducers/tutorialReducer.js
const initialState = {
    loading: false,
    tutorials: [],
    error: '',
    fileUploading: false,
    fileUploadError: ''
  };
  
  const tutorialReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_TUTORIAL_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_TUTORIAL_SUCCESS':
        return {
          loading: false,
          tutorials: action.payload,
          error: ''
        };
      case 'FETCH_TUTORIAL_FAILURE':
        return {
          loading: false,
          tutorials: [],
          error: action.payload
        };
        case 'UPLOAD_TUTORIAL_REQUEST':
        return {
          ...state,
          loading: true
        };
      case 'UPLOAD_TUTORIAL_SUCCESS':
        return {
          loading: false,
          error: ''
        };
      case 'UPLOAD_TUTORIAL_FAILURE':
        return {
          loading: false,
          error: action.payload
        };
      case 'UPLOAD_KNOWLEDGE_BANK_FILE_REQUEST':
        return {
          ...state,
          fileUploading: true
        };
      case 'UPLOAD_KNOWLEDGE_BANK_FILE_SUCCESS':
        return {
          fileUploading: false, 
          fileUploadError: ''
        };
      case 'UPLOAD_KNOWLEDGE_BANK_FILE_FAILURE':
        return {
          fileUploading: false,
          fileUploadError: action.payload
        };
      case 'UPLOAD_COMPANY_DOCUMENT_REQUEST':
        return {
          ...state,
          fileUploading: true
        };
      case 'UPLOAD_COMPANY_DOCUMENT_SUCCESS':
        return {
          fileUploading: false,  
          fileUploadError: ''
        };
      case 'UPLOAD_COMPANY_DOCUMENT_FAILURE':
        return {
          fileUploading: false,
          fileUploadError: action.payload
        };
      default:
        return state;
    }
  };
  
  export default tutorialReducer;
  