import React, { useCallback, useState, useContext, useEffect } from 'react';
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    MiniMap,
    useNodesState,
    useEdgesState,
    Handle,
    Position,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, Textarea, useDisclosure, useToast, Box, RadioGroup, Radio, Stack } from '@chakra-ui/react';
import { LanguageContext } from 'context/LanguageContext';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNarrative, createNarrative, deleteNarrativeNode } from "actions/narrativeAction";
import LineSpinnerComponent from 'components/spinner';

// CSS for node styling
const nodeStyles = {
    nodeContainer: {
        width: '125px', // Fixed width
        maxHeight: '150px', // Max height with scrolling if needed
        overflow: 'hidden',
        padding: '5px',
        height: '23px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        backgroundColor: 'white',
    },
    question: {
        fontSize: '8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    answer: {
        fontSize: '12px',
        marginTop: '5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
};

// Custom Node Component
const CustomNode = ({ data }) => {
    return (
        <div style={nodeStyles.nodeContainer}>
            <Handle type="target" position={Position.Top} />
            <div style={nodeStyles.question}>
                {data.question}
            </div>
            <Handle type="source" position={Position.Bottom} />
        </div>
    );
};

const nodeTypes = { customNode: CustomNode };

// Map provided data to React Flow nodes and edges
const mapDataToNodes = (data) => data.nodes.map(node => ({
    id: node.node_id,
    type: 'customNode',
    data: {
        question: node.question,
        answer: node.answer,
        speaker: node.speaker || 'User', // Default to 'User' if not specified
    },
    position: node.position,
}));

const mapDataToEdges = (data) => data.edges.map((edge, index) => ({
    id: `reactflow__edge-${edge.source_node_id}-${edge.target_node_id}`,
    source: edge.source_node_id,
    target: edge.target_node_id,
}));


const TreeFlow = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { narrativeData, loading } = useSelector(state => state?.narrativeData);
    console.log("🚀 ~ TreeFlow ~ narrativeData:", narrativeData)
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { translate, language } = useContext(LanguageContext);

    const resizeObserverErrHandler = e => {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            // Prevent the error from being logged.
            e.stopImmediatePropagation();
        }
    };
    
    window.addEventListener('error', resizeObserverErrHandler);

    // Update nodes and edges when narrativeData changes
    useEffect(() => {
        if (narrativeData) {
            narrativeData?.narrative && setNodes(mapDataToNodes(narrativeData?.narrative));
            narrativeData?.narrative && setEdges(mapDataToEdges(narrativeData?.narrative));
        }
    }, [narrativeData]);

    useEffect(() => {
        dispatch(fetchNarrative());
    }, [dispatch]);


    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    const addNode = useCallback(() => {
        const newNodeId = nodes.length + 1;
        const lastNode = nodes[nodes.length - 1];
        const newNodePosition = lastNode
        ? { x: lastNode.position.x + 50, y: lastNode.position.y + 50 }
        : { x: 0, y: 0 }; // Default position if no nodes exist
        const newNode = {
            id: newNodeId.toString(),
            type: 'customNode',
            data: { question: '', answer: '', speaker: 'User' },
            position: newNodePosition,
        };
        setNodes((nds) => nds.concat(newNode));
    }, [nodes, setNodes]);

    const deleteNode = useCallback(async (nodeId) => {
        try {
            const response = await dispatch(deleteNarrativeNode(nodeId));
            setNodes([]);
        setEdges([]);
            setNodes((nds) => nds.filter((node) => node.id !== nodeId));
            setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
            onClose();
            await dispatch(fetchNarrative());
            toast({
                title: 'Success',
                description: 'Node deleted successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top',
            })
        }
        catch (e) {
            toast({
                title: 'Error',
                description: 'Failed to delete node, please try again!',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            return;
        }

    }, [setNodes, setEdges, onClose]);

    const handleNodeClick = useCallback((event, node) => {
        setSelectedNode(node);
        onOpen();
    }, [onOpen]);

    const disconnectNode = useCallback((nodeId) => {
        setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
    }, [setEdges]);

    const handleUpdateNode = useCallback(() => {
        setNodes((nds) =>
            nds.map((node) =>
                node.id === selectedNode.id
                    ? { ...node, data: { ...node.data, question: selectedNode.data.question, answer: selectedNode.data.answer, speaker: selectedNode.data.speaker } }
                    : node
            )
        );
        onClose();
    }, [selectedNode, setNodes, onClose]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedNode((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
            },
        }));
    };

    const handleSpeakerChange = (value) => {
        setSelectedNode((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                speaker: value,
            },
        }));
    };

    const saveToDatabase = useCallback(async () => {
        const payload = {
            nodes: nodes.map((node) => ({
                id: node.id,
                type: node.type,
                data: {
                    question: node.data.question,
                    answer: node.data.answer,
                    speaker: node.data.speaker,
                },
                position: node.position,
            })),
            edges: edges.map((edge) => ({
                id: edge.id,
                source: edge.source,
                target: edge.target,
            })),
        };
        setNodes([]);
        setEdges([]);
        console.log("🚀 ~ saveToDatabase ~ payload:", { narrative: payload })

        try {
            await dispatch(createNarrative({ narrative: payload }));
            // await dispatch(fetchNarrative());
            toast({
                title: "Success",
                description: "Narrative created successfully.",
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        } catch (error) {
            console.error("Error while saving to database:", error);
            toast({
                title: "Error",
                description: "Error while creating narrative. Please try again.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        }
    }, [nodes, edges, dispatch, toast]);

    return (
        <>
            {loading ? <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                <LineSpinnerComponent />
            </Box> : <Box style={{ height: 600, marginTop: 150 }}>
                <Flex justifyContent="flex-end" mt='80px'>
                    <Button
                        bg="#F69320"
                        color="white"
                        _hover={{ bg: "#F67332" }}
                        onClick={addNode}
                        className={language === 'ar' && "arabic_font"}
                    >
                        {translate("add-node")}
                    </Button>
                    <Button
                        ml={4}
                        bg="#EF4959"
                        color="white"
                        _hover={{ bg: "#E43D50" }}
                        onClick={saveToDatabase}
                        className={language === 'ar' && "arabic_font"}
                    >
                        {translate("btn-save")}
                    </Button>
                </Flex>
                {nodes?.length > 0 && <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onNodeClick={handleNodeClick}
                    fitView
                    nodeTypes={nodeTypes}
                >
                    <Background />
                    <MiniMap />
                    <Controls />
                </ReactFlow>}

                {/* Modal to show and edit node data */}
                {loading ? <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                <LineSpinnerComponent />
            </Box> : selectedNode && (
                    <Modal isOpen={isOpen} onClose={onClose} isCentered>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader
                                className={language === 'ar' && "arabic_font"}
                                dir={language === 'ar' ? 'rtl' : 'ltr'}
                            >{translate("update-node-details")}</ModalHeader>
                            <ModalBody>
                                <div>
                                    <label
                                        className={language === 'ar' && "arabic_font"}
                                        dir={language === 'ar' ? 'rtl' : 'ltr'}
                                        style={language === 'ar' ? { textAlign: 'right', display: 'flex' } : {}}
                                    >{translate("question")}</label>
                                    <Input
                                        className={language === 'ar' && "arabic_font"}
                                        dir={language === 'ar' ? 'rtl' : 'ltr'}
                                        placeholder={translate("question")}
                                        name="question"
                                        value={selectedNode.data.question}
                                        onChange={handleChange}
                                        mb={4}
                                    />
                                </div>
                                <div>
                                    <label
                                        className={language === 'ar' && "arabic_font"}
                                        dir={language === 'ar' ? 'rtl' : 'ltr'}
                                        style={language === 'ar' ? { textAlign: 'right', display: 'flex' } : {}}
                                    >{translate("answer")}</label>
                                    <Textarea
                                        className={language === 'ar' && "arabic_font"}
                                        dir={language === 'ar' ? 'rtl' : 'ltr'}
                                        placeholder={translate("answer")}
                                        name="answer"
                                        value={selectedNode.data.answer}
                                        onChange={handleChange}
                                        mb={4}
                                    />
                                </div>
                                <div>
                                    <label
                                        className={language === 'ar' && "arabic_font"}
                                        dir={language === 'ar' ? 'rtl' : 'ltr'}
                                        style={language === 'ar' ? { textAlign: 'right', display: 'flex' } : {}}
                                    >{translate("speaker")}</label>
                                    <RadioGroup onChange={handleSpeakerChange} value={selectedNode.data.speaker} mb={4}>
                                        <Stack direction="row">
                                            <Radio value="User">{translate("user")}</Radio>
                                            <Radio value="Character">{translate("character")}</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button bg="#F69320"
                                    color="white"
                                    _hover={{ bg: "#F67332" }} onClick={handleUpdateNode}
                                    className={language === 'ar' && "arabic_font"}
                                >
                                    {translate("btn-save")}
                                </Button>
                                <Button colorScheme="red" onClick={() => deleteNode(selectedNode.id)} ml={3}
                                    className={language === 'ar' && "arabic_font"}
                                >
                                    {translate("delete-node")}
                                </Button>
                                <Button bg="#F69320"
                                    color="white"
                                    _hover={{ bg: "#F67332" }} onClick={() => disconnectNode(selectedNode.id)} ml={3}
                                    className={language === 'ar' && "arabic_font"}
                                >
                                    {translate("disconnect")}
                                </Button>
                                <Button onClick={onClose} ml={3}
                                    className={language === 'ar' && "arabic_font"}
                                >
                                    {translate("close")}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                )}
            </Box>}
        </>
    );
};

export default TreeFlow;
